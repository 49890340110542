import { DailyKpi } from './interfaces/Kpi.ts';

export const SHIFT_STATUS_VALIDATE = 'VALIDATE';
export const SHIFT_STATUS_ONGOING = 'ONGOING';
export const SHIFT_STATUS_CANCEL = 'CANCEL';
export const SHIFT_STATUS_PAUSED = 'PAUSED';
export const SHIFT_STATUS_FINISHED = 'FINISHED';

export const ACTION_NAME_PAUSE_SHIFT = 'pause-shift';
export const ACTION_NAME_UNPAUSE_SHIFT = 'unpause-shift';
export const ACTION_NAME_START_SHIFT = 'start-shift';
export const ACTION_NAME_IN_PLACE = 'in-place';
export const ACTION_NAME_READY_TO_LEAVE = 'ready-to-leave';
export const ACTION_NAME_PICK_UP = 'pick-up';
export const ACTION_NAME_ABSENT = 'absent';
export const ACTION_NAME_DROP_OFF = 'drop-off';

export const MISSION_LIFECYCLE_PENDING = 'PENDING';
export const MISSION_LIFECYCLE_START = 'START';
export const MISSION_LIFECYCLE_DEPARTURE = 'DEPARTURE';
export const MISSION_LIFECYCLE_ON_THE_WAY = 'ON_THE_WAY';
export const MISSION_LIFECYCLE_PAUSE = 'PAUSE';
export const MISSION_LIFECYCLE_ARRIVAL = 'ARRIVAL';
export const MISSION_LIFECYCLE_ARRIVED = 'ARRIVED';
export const MISSION_LIFECYCLE_SUCCESS = 'SUCCESS';
export const MISSION_LIFECYCLE_REJECTED = 'REJECTED';
export const MISSION_LIFECYCLE_REMOVED = 'REMOVED';
export const MISSION_LIFECYCLE_ABORTED = 'ABORTED';
export const MISSION_LIFECYCLE_TIMEOUT = 'TIMEOUT';
export const MISSION_LIFECYCLE_UNKNOWN_IN_FM = 'UNKNOWN_IN_FM';
export const MISSION_LIFECYCLE_LOST_IN_FM = 'LOST_IN_FM';
export const MISSION_LIFECYCLE_LOST_IN_VEHICLE = 'LOST_IN_VEHICLE';
export const MISSION_LIFECYCLE_UNKNOWN_IN_VEHICLE = 'UNKNOWN_IN_VEHICLE';
export const MISSION_LIFECYCLE_OUT_OF_ITINERARY = 'OUT_OF_ITINERARY';
export const MISSION_LIFECYCLES = [
  MISSION_LIFECYCLE_PENDING,
  MISSION_LIFECYCLE_START,
  MISSION_LIFECYCLE_DEPARTURE,
  MISSION_LIFECYCLE_ON_THE_WAY,
  MISSION_LIFECYCLE_PAUSE,
  MISSION_LIFECYCLE_ARRIVAL,
  MISSION_LIFECYCLE_ARRIVED,
  MISSION_LIFECYCLE_SUCCESS,
  MISSION_LIFECYCLE_REJECTED,
  MISSION_LIFECYCLE_REMOVED,
  MISSION_LIFECYCLE_ABORTED,
  MISSION_LIFECYCLE_TIMEOUT,
  MISSION_LIFECYCLE_UNKNOWN_IN_FM,
  MISSION_LIFECYCLE_LOST_IN_FM,
  MISSION_LIFECYCLE_LOST_IN_VEHICLE,
  MISSION_LIFECYCLE_UNKNOWN_IN_VEHICLE,
  MISSION_LIFECYCLE_OUT_OF_ITINERARY,
];

export const PLACE_ACTIVITY_PICK_AND_DROP = 'PICK AND DROP';
export const PLACE_ACTIVITY_MAINTENANCE = 'MAINTENANCE';
export const PLACE_ACTIVITY_PARKING = 'PARKING';
export const PLACE_STATUS_IN_PLACE = 'IN_PLACE';
export const PLACE_STATUS_VISITED = 'VISITED';
export const PLACE_STATUS_NOT_VISITED = 'NOT_VISITED';
export const PLACE_STATUS_WAITING_FOR_CONFIRM = 'PLACE_STATUS_WAITING_FOR_CONFIRM';
export const PLACE_STATUS_WAITING_FOR_CANCELLED_ACKNOWLEDGEMENT =
  'PLACE_STATUS_WAITING_FOR_CANCELLED_ACKNOWLEDGEMENT';
export const PLACE_ACTIVITIES = [
  { name: PLACE_ACTIVITY_PICK_AND_DROP, number: 3 },
  { name: PLACE_ACTIVITY_MAINTENANCE, number: 4 },
  { name: PLACE_ACTIVITY_PARKING, number: 5 },
];

export const AUTOMATA_OFF = 'OFF';
export const AUTOMATA_LEVEL_3 = 'LEVEL_3';
export const AUTOMATA_LEVEL_4 = 'LEVEL_4';

export const BOOKING_STATUS_ACTION_PICKUP = 'PICKUP';
export const BOOKING_STATUS_ACTION_DROPOFF = 'DROPOFF';

export const PASSENGER_STATUS_ONBOARD = 'ONBOARD';
export const PASSENGER_STATUS_DROPOFF = 'DROPOFF';
export const PASSENGER_STATUS_CANCELED = 'CANCELED';

export const LOG_TYPE_AUTOMATA = 'AUTOMATA';
export const LOG_TYPE_VEHICLE = 'VEHICLE';

export const ALERT_LIFECYCLE_UNRESOLVED = 'UNRESOLVED';
export const ALERT_LIFECYCLE_RESOLVED = 'RESOLVED';

export const ALERT_MESSAGE_TYPE_CREATION = 'ALERT_CREATION';

export const KPI_VEHICLE_GEOLOCATION = 'vehicle_geolocations';
export const KPI_TERRITORY = 'territory_kpis';
export const KPI_TERRITORY_AVERAGE_SPEED = 'territory_average_speed';
export const KPI_TERRITORY_AVERAGE_PARTNER_MODE_RATIO = 'territory_average_partner_mode_ratio';
export const KPI_TERRITORY_AVERAGE_AUTONOMOUS_MODE_RATIO = 'territory_average_autonomous_mode_ratio';
export const KPI_LIST: (keyof DailyKpi)[] = [
  KPI_TERRITORY_AVERAGE_SPEED,
  KPI_TERRITORY_AVERAGE_PARTNER_MODE_RATIO,
  KPI_TERRITORY_AVERAGE_AUTONOMOUS_MODE_RATIO,
];
