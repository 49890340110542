import { Accordion, Col, Container, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import { FormatDate } from '../../../utils.ts';
import { IMission } from '../../../interfaces/IMission.ts';
import { useContext, useEffect, useState } from 'react';
import { patchMissions } from '../../../queries/getMissions.ts';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MISSION_LIFECYCLES } from '../../../constants.ts';
import { SelectedNodeContext } from '../../../contexts/SelectedNodeContext.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '../../../ui/Button.tsx';
import { MissionLifecycleBadge } from '../../../ui/Badge.tsx';
import { useTerritoryConfig } from '../../../services/Territory.ts';

interface Props {
  mission: IMission;
  index: number;
}

export function MissionCard(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mission, setMission] = useState(props.mission);
  const [showEditMission, setShowEditMission] = useState(false);
  const { setSelectedNode } = useContext(SelectedNodeContext);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams() as { territorySlug: string };
  const { data: territoryConfig } = useTerritoryConfig(params.territorySlug);
  const handleCloseEditMission = () => setShowEditMission(false);
  const handleShowEditMission = () => setShowEditMission(true);

  useEffect(() => {
    if (props.mission != undefined) {
      setMission(props.mission);
    }
  }, [props.mission]);

  const mutation = useMutation({
    mutationFn: (payload: { lifecycle: string }) =>
      patchMissions(params.territorySlug, mission.id, payload),
    onSuccess: (_data, variables) => {
      setMission({ ...mission, lifecycle: variables.lifecycle });
      setShowEditMission(false);
      toast.success(
        `${t('mission.mission')} ${mission.id} ${t('mission.new-lifecycle')} ${mission.lifecycle}`,
      );
    },
    onError: () => {
      toast.error(`${t('mission.mission')} ${mission.id} ${t('mission.failed-lifecycle')}`);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmitMissionEdit(event) {
    event.preventDefault();
    setIsLoading(true);
    const payload = {
      lifecycle: event.target.elements.lifecycleSelect.value,
    };
    mutation.mutate(payload);
  }

  function redirectMissionHistoryDetail() {
    navigate(`/mission-history/${params.territorySlug}/detail/${mission.id}`);
  }

  const onEnter = () => {
    setSelectedNode({ name: mission.dispatch_destination_node_name });
  };
  const onExit = () => {
    setSelectedNode({ name: null });
  };

  return (
    <Accordion.Item eventKey={String(props.index)} className={'basic-shadow ms-3 me-3 rounded-3 mt-2'}>
      <Accordion.Header className={'rounded'}>
        <Row className={'w-100'}>
          <Col lg={12} xl={8} className={'my-auto'}>
            <Row>
              <Col lg={6} xl={6} className={'my-auto'}>
                <p className={'text-bold mission-color'}>
                  <img src={'/theme/media/target.svg'} width="20" height="20" alt="Target icon" />
                  <span className={'ms-1'}>{mission.id}</span>
                </p>
              </Col>
              <Col lg={6} xl={6} className={'my-auto'}>
                <p className={'text-basic'}>
                  <img src={'/theme/media/small-av.svg'} width="20" height="20" alt="Small av icon" />
                  <span className={'ms-1'}>{mission.vehicle_display_identifier}</span>
                </p>
              </Col>
            </Row>
            <p className={'text-basic mt-1'}>
              <img src={'/theme/media/note-pencil.svg'} width="20" height="20" alt="Note pencil icon" />
              <span className={'ms-1'}>{mission.created_by}</span>
            </p>
            <p className={'text-basic geography-color mt-1'}>
              <img src={'/theme/media/pin.svg'} width="20" height="20" alt="Destination icon" />
              <a className={'ms-1'}>
                {mission.dispatch_destination_node_name
                  ? mission.dispatch_destination_node_name.toLowerCase()
                  : '-'}
              </a>
            </p>
          </Col>
          <Col lg={12} xl={4} className={'my-auto'}>
            <p className={'text-xl-end mt-2'}>
              <MissionLifecycleBadge lifecycle={mission.lifecycle} />
            </p>
            {territoryConfig?.data?.mission.progress_display ? (
              <ProgressBar
                className={'mission-progress-bar mt-3'}
                now={Number(mission.progress) | 0}
                label={`${Number(mission.progress) | 0}%`}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body
        className={'mb-1 text-center ms-3 me-3 ps-0 pe-0'}
        onEnter={onEnter}
        onExit={onExit}>
        <Container fluid>
          {mission.shift_id ? (
            <Row>
              <Col className={'text-start'}>
                <p className={'text-bold shift-color'}>
                  <img src={'/theme/media/shift.svg'} width="20" height="20" alt="Shift icon" />
                  <span className={'ms-1'}>{mission.shift_id}</span>
                </p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className={'w-100 m-0 p-0'}>
            <Col lg={12} xl={6} className={'text-start m-0 p-0'}>
              <p className={'text-bold'}>
                {t('mission.created-at')}
                {' : '}
              </p>
              <p className={'text-basic'}>{FormatDate(mission.created_at)}</p>
            </Col>
            <Col lg={12} xl={6} className={'text-start m-0 p-0'}>
              <p className={'text-bold'}>
                {t('mission.updated-at')}
                {' : '}
              </p>
              <p className={'text-basic'}>{FormatDate(mission.updated_at)}</p>
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col>
              <LoadingButton
                type={'submit-button'}
                text={t('mission.edit')}
                imagePath={'/theme/media/pencil.svg'}
                loading={false}
                onClick={() => handleShowEditMission()}
              />
            </Col>
            <Col>
              <LoadingButton
                type={'green-button'}
                text={t('mission.history-link')}
                imagePath={'/theme/media/green-clock.svg'}
                loading={false}
                onClick={() => redirectMissionHistoryDetail()}
              />
            </Col>
          </Row>
          <div className={'create-mission-modal'}>
            <Modal show={showEditMission} onHide={handleCloseEditMission} centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  {t('mission.mission')} #{mission.id}
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={onSubmitMissionEdit}>
                <Modal.Body>
                  <Form.Group as={Row} className={'d-flex align-items-center'}>
                    <Col sm={3}>
                      <Form.Label>Lifecycle</Form.Label>
                    </Col>
                    <Col sm={9}>
                      <Form.Select
                        id={'lifecycleSelect'}
                        className={'rounded-5'}
                        defaultValue={mission.lifecycle}>
                        {MISSION_LIFECYCLES.map((lifecycle, i) => (
                          <option value={lifecycle} key={i}>
                            {lifecycle}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer className={'border-0'}>
                  <LoadingButton
                    type={'submit-button'}
                    text={t('mission.edit-submit')}
                    imagePath={'/theme/media/pencil.svg'}
                    loading={isLoading}
                    onClick={undefined}
                  />
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
