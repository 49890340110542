import { Header } from './Header';
import { Footer } from './Footer';
import React from 'react';
import { Container, Row } from 'react-bootstrap';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div>
      <Header />
      <Container fluid className={'m-0 p-0'}>
        <Row className={'m-0 p-0'}>
          <div className={'content-container'}>
            <Row>{children}</Row>
          </div>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default Layout;
