import { Shift } from './Shift/Shift.tsx';
import { Map } from './Map/Map.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { Kpi } from './Kpi/Kpi.tsx';
import { Roadmap } from './Roadmap/Roadmap.tsx';
import { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Mission } from './Mission/Mission.tsx';
import { getAlertWording } from '../../utils.ts';
import { SelectedVehicleContext } from '../../contexts/SelectedVehicleContext.tsx';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { websocketUrl } from '../../queries/constants.ts';
import { processVehicleMessage } from '../../websocket/processing.ts';
import { IVehicle } from '../../interfaces/Vehicle.ts';
import { useVehiclesShiftByTerritory } from '../../services/Vehicle.ts';
import { AlertMessage, RoadmapMessage, VehicleMessage } from '../../interfaces/Message.ts';
import { ALERT_MESSAGE_TYPE_CREATION } from '../../constants.ts';
import { toast } from 'react-toastify';
import { useWebsocketToken } from '../../services/Websocket.ts';
import { useParams } from 'react-router-dom';

export function Dashboard() {
  const params = useParams() as { territorySlug: string };
  const queryClient = useQueryClient();

  const { selectedVehicle } = useContext(SelectedVehicleContext);

  const vehicleWS = useWebSocket<VehicleMessage>(websocketUrl, {
    share: false,
    shouldReconnect: () => true,
  });

  const roadmapWS = useWebSocket<RoadmapMessage>(websocketUrl, {
    share: false,
    shouldReconnect: () => true,
  });

  const alertWS = useWebSocket<AlertMessage>(websocketUrl, {
    share: false,
    shouldReconnect: () => true,
  });

  const { data: websocketToken } = useWebsocketToken();

  const vehiclesData = useVehiclesShiftByTerritory(params.territorySlug);

  useEffect(() => {
    const vehiclesNames = vehiclesData?.data?.map((vehicle: IVehicle) => vehicle.provider_identifier);
    if (
      vehicleWS.readyState === ReadyState.OPEN &&
      websocketToken &&
      vehiclesNames !== undefined &&
      vehiclesNames.length > 0
    ) {
      vehicleWS.sendJsonMessage({
        type: 'init',
        auth_token: websocketToken?.data.WEBSOCKET_TOKEN,
        object_type: 'vehicle_states',
        object_id_list: vehiclesNames,
      });
    }
  }, [vehicleWS.readyState, websocketToken, vehiclesData]);

  useEffect(() => {
    const vehiclesNames = vehiclesData?.data?.map((vehicle: IVehicle) => vehicle.provider_identifier);
    if (
      roadmapWS.readyState === ReadyState.OPEN &&
      websocketToken &&
      vehiclesNames !== undefined &&
      vehiclesNames.length > 0
    ) {
      roadmapWS.sendJsonMessage({
        type: 'init',
        auth_token: websocketToken?.data.WEBSOCKET_TOKEN,
        object_type: 'roadmap',
        object_id_list: vehiclesNames,
      });
    }
  }, [roadmapWS.readyState, websocketToken, vehiclesData]);

  useEffect(() => {
    if (alertWS.readyState === ReadyState.OPEN && websocketToken) {
      alertWS.sendJsonMessage({
        type: 'init',
        auth_token: websocketToken?.data.WEBSOCKET_TOKEN,
        object_type: 'alert',
        object_id_list: [params.territorySlug],
      });
    }
  }, [alertWS.readyState, params.territorySlug, websocketToken]);

  useEffect(() => {
    if (alertWS.lastJsonMessage?.type === ALERT_MESSAGE_TYPE_CREATION) {
      queryClient.invalidateQueries({ queryKey: ['alert', params.territorySlug] });
      toast.error(
        `${alertWS.lastJsonMessage.content_object_display_name} - ${getAlertWording(alertWS.lastJsonMessage.code)}`,
      );
    }
  }, [alertWS.lastJsonMessage]);

  useEffect(() => {
    if (
      vehicleWS.lastJsonMessage?.message_type === 'vehicle_states' &&
      vehiclesData?.data !== undefined
    ) {
      processVehicleMessage(vehicleWS.lastJsonMessage, vehiclesData.data);
    }
  }, [vehicleWS.lastJsonMessage]);

  return (
    <Container className={'dashboard-container'}>
      <Row>
        <Col sm={3} className={'pe-0 dashboard-col'}>
          <Shift />
        </Col>
        <Col sm={6} className={'dashboard-col'}>
          <div>
            {selectedVehicle?.id === undefined ? (
              <Kpi />
            ) : (
              <>
                <Roadmap lastRoadmapWS={roadmapWS.lastJsonMessage} />
              </>
            )}
          </div>
          <Map />
        </Col>
        <Col sm={3} className={'ps-0 dashboard-col'}>
          <Mission />
        </Col>
      </Row>
    </Container>
  );
}
