import { Badge } from 'antd';
import { getLifecycleColors } from '../utils.ts';
import { useAlert } from '../services/Alerts.ts';
import { useParams } from 'react-router-dom';

interface Props {
  onClick: () => void;
}

export function NotificationBadge(props: Props) {
  const params = useParams() as { territorySlug: string };
  const { data: alertData } = useAlert(params.territorySlug);
  return (
    <Badge count={alertData?.data?.results?.length} className={'ariane'} onClick={props.onClick}>
      <img src={'/theme/media/warning.svg'} width="30" alt="notification icon" />
    </Badge>
  );
}

export function MissionLifecycleBadge(props: { lifecycle: string }) {
  return (
    <span className={'mission-lifecycle rounded-4'} style={getLifecycleColors(props.lifecycle)}>
      {props.lifecycle}
    </span>
  );
}

export function VehicleWarningBadge() {
  return <Badge status="processing" className={'ms-1'} color={'red'} />;
}
