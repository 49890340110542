import { useQuery } from '@tanstack/react-query';
import { getWebsocketToken } from '../queries/getWebsocketToken.ts';

export const useWebsocketToken = () => {
  return useQuery({
    queryKey: ['websocketToken'],
    queryFn: () => getWebsocketToken(),
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });
};
