import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function OverlayTooltip(props: { text: string; children: React.ReactElement }) {
  return (
    <OverlayTrigger
      placement="top-start"
      delay={{ show: 250, hide: 250 }}
      overlay={
        <Tooltip>
          <p className={'text-start'}>{props.text}</p>
        </Tooltip>
      }>
      {props.children}
    </OverlayTrigger>
  );
}
