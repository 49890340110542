import { useQuery } from '@tanstack/react-query';
import { getStatistics } from '../queries/getStatistics.ts';
import { getIsoFormatDatetime } from '../utils.ts';
import { KPI_TERRITORY, KPI_VEHICLE_GEOLOCATION } from '../constants.ts';
import { MissionHistory } from '../interfaces/IMission.ts';

export const useLiveStatistics = (territorySlug: string) => {
  const params: Record<string, string> = {
    start_date: getIsoFormatDatetime(6, true),
    end_date: getIsoFormatDatetime(20, false),
    metrics: KPI_TERRITORY,
  };
  return useQuery({
    queryKey: ['liveStatistics', territorySlug],
    queryFn: () => getStatistics(territorySlug, params),
    retry: false,
  });
};

export const useVehicleGeolocation = (
  territorySlug: string,
  vehicleId: number | undefined,
  history: MissionHistory | undefined,
) => {
  let params = {};
  const lifecycles = history?.lifecycles;
  if (lifecycles !== undefined) {
    const youngestLifecycle = lifecycles?.[0].updated_at;
    const oldestLifecycle = lifecycles?.[lifecycles.length - 1].updated_at;
    params = {
      start_date: oldestLifecycle,
      end_date: youngestLifecycle,
      metrics: KPI_VEHICLE_GEOLOCATION,
      vehicle_id: String(vehicleId),
    };
  }
  return useQuery({
    queryKey: ['geolocations', territorySlug, vehicleId, history?.id],
    queryFn: () => getStatistics(territorySlug, params),
    enabled: vehicleId !== undefined && history?.lifecycles !== undefined,
    retry: false,
  });
};
