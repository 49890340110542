import { Key, useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  doorStatusColor,
  formatUpdatedAt,
  getAutomataOffButtonColor,
  getBatteryColor,
  getBatteryType,
  getMissionCreationPayload,
  showVehicleAlertBadge,
} from './utils.ts';
import { patchVehicle, postCreateMission } from '../../../queries/getVehicles.ts';
import { getNodeNameById, getShiftStatusColors } from '../../../utils.ts';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FormatTime } from '../../../utils.ts';
import {
  AUTOMATA_LEVEL_3,
  AUTOMATA_LEVEL_4,
  AUTOMATA_OFF,
  PLACE_ACTIVITIES,
} from '../../../constants.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { useShiftByVehicle } from '../../../services/Vehicle.ts';
import { AutomataFeatures } from './AutomataFeatures.tsx';
import { LoadingButton } from '../../../ui/Button.tsx';
import { Log } from '../Logs/Log.tsx';
import { VehicleDisplayIdentifier } from './VehicleDisplayIdentifier.tsx';
import { VehicleWarningBadge } from '../../../ui/Badge.tsx';
import { useGeography } from '../../../services/Geography.ts';
import { useTerritory, useTerritoryConfig } from '../../../services/Territory.ts';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NodeType } from '../../../interfaces/Node.ts';
interface Props {
  vehicle: IVehicle;
  index: number;
}

export function Vehicle(props: Props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSelectedVehicle } = useContext(SelectedVehicleContext);
  const [isCreatingMission, setIsCreatingMission] = useState(false);
  const params = useParams() as { territorySlug: string };
  const { data: geography } = useGeography(params.territorySlug);
  const [showAlertBadge, setShowAlertBadge] = useState(false);
  const [showCreateMission, setShowCreateMission] = useState(false);
  const [showAutomataFeatures, setShowAutomataFeatures] = useState(false);
  const [showDisplayIdentifierModal, setShowDisplayIdentifierModal] = useState(false);
  const [showLogs, setShowLogs] = useState<boolean>(false);

  const shift = useShiftByVehicle(params.territorySlug, props.vehicle?.id || 0);
  const { data: territoryConfig } = useTerritoryConfig(params.territorySlug);
  const { data: territory } = useTerritory(params.territorySlug);

  useEffect(() => {
    setShowAlertBadge(showVehicleAlertBadge(props.vehicle));
  }, [props.vehicle, props.vehicle?.shift]);

  const onEnter = () => {
    setSelectedVehicle(props.vehicle);
  };
  const onExit = () => {
    setSelectedVehicle(undefined);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmitMissionCreation(event) {
    setIsCreatingMission(true);
    event.preventDefault();
    try {
      await postCreateMission(
        params.territorySlug,
        props.vehicle.id,
        getMissionCreationPayload(event, territoryConfig?.data),
      );
      toast.success(
        `${t('vehicle.create-mission-toaster-success')} ${props.vehicle.display_identifier}`,
      );
    } catch (e) {
      toast.error(`${t('vehicle.create-mission-toaster-error')} ${props.vehicle.display_identifier}`);
    } finally {
      setIsCreatingMission(false);
      setShowCreateMission(false);
    }
  }
  const vehicleMutation = useMutation({
    mutationFn: (payload: { automata_state: string }) =>
      patchVehicle(params.territorySlug, props.vehicle.id, payload),
    onMutate: async data => {
      await queryClient.cancelQueries({ queryKey: ['vehicles', params.territorySlug] });
      const previousVehicles = queryClient.getQueryData<{ data?: IVehicle[] }>([
        'vehicles',
        params.territorySlug,
      ]);
      const optimisticVehicles = previousVehicles?.data?.map(vehicle =>
        vehicle.id === props.vehicle.id ? { ...vehicle, automata_state: data.automata_state } : vehicle,
      );
      queryClient.setQueryData(['vehicles', params.territorySlug], {
        ...previousVehicles,
        data: optimisticVehicles,
      });
    },
    onError: () => {
      toast.error(
        `${t('vehicle.vehicle')} ${props.vehicle.display_identifier} ${t('vehicle.automata-update-error')}`,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['vehicles', params.territorySlug] });
    },
  });

  return (
    <Accordion.Item eventKey={String(props.index)} className={'basic-shadow rounded-3 mt-2'}>
      <Accordion.Header className={'rounded'}>
        <Row className={'w-100'}>
          <Col lg={12} xl={7} className={'my-auto text-start'}>
            <Row>
              <Col lg={4}>
                {props.vehicle.is_online ? (
                  <img
                    src={`/theme/media/shuttle-connected.svg`}
                    height="48"
                    className="rounded-2"
                    alt="connected shuttle"
                  />
                ) : (
                  <img
                    src={`/theme/media/shuttle-disconnected.svg`}
                    height="48"
                    className="rounded-2"
                    alt="disconnected shuttle"
                  />
                )}
              </Col>
              <Col lg={8} className={'my-auto'}>
                <p className={'card-text m-0'}>
                  {props.vehicle.display_identifier} {showAlertBadge ? <VehicleWarningBadge /> : <></>}
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={5} className={'my-auto ps-3 border-start'}>
            <p className={'text-bold shift-color'}>
              <img src={'/theme/media/shift.svg'} width="20" height="20" alt="Shift icon" />
              <span className={'ms-1'}>
                {shift?.data?.data?.id ? `${shift?.data?.data?.id}` : `${t('vehicle.no-shift')}`}
              </span>
            </p>
            {shift?.data?.data?.id ? (
              <>
                <p className={'mt-1'}>
                  <img src={'/theme/media/clock.svg'} width="20" height="20" alt="Clock icon" />
                  <span className={'ms-1 text-muted'}>
                    {FormatTime(shift?.data?.data?.start_datetime, false)} -{' '}
                    {FormatTime(shift?.data?.data?.end_datetime, false)}
                  </span>
                </p>
                <p className={'text-basic mt-3 pb-1'}>
                  <span
                    className={'mission-lifecycle rounded-4'}
                    style={getShiftStatusColors(shift?.data?.data?.status)}>
                    {shift?.data?.data?.status}
                  </span>
                </p>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body onEntered={onEnter} onExit={onExit} className={'mb-3'}>
        <Container>
          <Form>
            <Row>
              <Col lg={12} xl={5} className={'my-auto'}>
                <p className={'text-bold text-start'}>
                  {props.vehicle.display_identifier}
                  <a className={'ariane ms-1'} onClick={() => setShowDisplayIdentifierModal(true)}>
                    <img
                      src={'/theme/media/pencil-black.svg'}
                      width="17"
                      height="17"
                      alt="Vehicle name"
                    />
                  </a>
                </p>
              </Col>
            </Row>
            <Row className={'mt-2'}>
              <Col lg={12} xl={5} className={'my-auto'}>
                <p className={'text-basic text-start'}>{t('vehicle.automata-enabled')}</p>
              </Col>
              <VehicleDisplayIdentifier
                vehicle={props.vehicle}
                showDisplayIdentifierModal={showDisplayIdentifierModal}
                setShowDisplayIdentifierModal={setShowDisplayIdentifierModal}
              />
              <Col lg={12} xl={7} className={'my-auto text-start text-xl-end'}>
                <ButtonGroup className={'shadow-sm rounded-start-4 rounded-end-4'}>
                  <Button
                    className={'rounded-start-4 automata-button'}
                    onClick={() => vehicleMutation.mutate({ automata_state: AUTOMATA_OFF })}
                    variant={getAutomataOffButtonColor(props.vehicle)}
                    active={props.vehicle.automata_state === AUTOMATA_OFF}>
                    off
                  </Button>
                  <Button
                    className={'automata-button'}
                    onClick={() => vehicleMutation.mutate({ automata_state: AUTOMATA_LEVEL_3 })}
                    active={props.vehicle.automata_state === AUTOMATA_LEVEL_3}>
                    3
                  </Button>
                  <Button
                    className={'rounded-end-4 automata-button'}
                    onClick={() => vehicleMutation.mutate({ automata_state: AUTOMATA_LEVEL_4 })}
                    active={props.vehicle.automata_state === AUTOMATA_LEVEL_4}>
                    4
                  </Button>
                </ButtonGroup>
                <a className={'ariane'} onClick={() => setShowAutomataFeatures(true)}>
                  <img
                    src={'/theme/media/settings-logo.svg'}
                    width="25"
                    height="25"
                    className={'ms-2'}
                    alt="Automata settings"
                  />
                </a>
                <AutomataFeatures
                  vehicle={props.vehicle}
                  showAutomataFeatures={showAutomataFeatures}
                  setShowAutomataFeatures={setShowAutomataFeatures}
                />
              </Col>
            </Row>
          </Form>
          <Row className={'mt-2'}>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.last-message-received')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {formatUpdatedAt(props.vehicle.updated_at)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.shuttle-provider')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.provider.toLowerCase()}
              </p>
            </Col>
          </Row>
          {territoryConfig?.data?.vehicle.charge_percentage_display ? (
            <Row>
              <Col lg={12} xl={5}>
                <p className={'text-basic text-start'}>{t('vehicle.battery-level')}</p>
              </Col>
              <Col xs={12} xl={7}>
                <p className={'text-basic text-start text-xl-end'}>
                  <i
                    className={
                      'text-basic bi bi-battery' +
                      getBatteryType(Number(props.vehicle.charge_percentage)) +
                      ' ' +
                      'text' +
                      getBatteryColor(Number(props.vehicle.charge_percentage))
                    }>
                    {props.vehicle.charge_percentage}%
                  </i>
                </p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {territoryConfig?.data?.doors_status_display ? (
            <Row>
              <Col lg={12} xl={5}>
                <p className={'text-basic text-start'}>{t('vehicle.door-status')}</p>
              </Col>
              <Col lg={12} xl={7}>
                <p
                  className={
                    'text-basic text-start text-xl-end text-' +
                    doorStatusColor(props.vehicle.door_status)
                  }>
                  {props.vehicle.door_status.toLowerCase()}
                </p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.robot-mode')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.robot_mode.toLowerCase()}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.supervision-mode')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.supervision_mode.toLowerCase()}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.latitude')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.latitude?.toFixed(6) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.longitude')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.longitude?.toFixed(6) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.current-node')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {getNodeNameById(geography?.data, props.vehicle.current_node) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.next-node')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {getNodeNameById(geography?.data, props.vehicle.next_node) || '-'}
              </p>
            </Col>
          </Row>
          <hr className={'text-muted'} />
          <Row className={'g-2 text-start'}>
            {territory?.data?.permissions.can_create_missions ? (
              <Col>
                <Button onClick={() => setShowCreateMission(true)} className={'submit-button'}>
                  <p>
                    <img src={'/theme/media/plus.svg'} width="25" height="25" alt="Plus icon" />
                    <span className={'ms-1 my-auto'}>{t('vehicle.create-mission-button')}</span>
                  </p>
                </Button>
              </Col>
            ) : (
              <></>
            )}
            <Col>
              <LoadingButton
                type={'submit-button'}
                loading={false}
                imagePath={'/theme/media/log.svg'}
                text={t('logs.button')}
                onClick={() => setShowLogs(true)}
              />
              <Log vehicle={props.vehicle} showLogs={showLogs} setShowLogs={setShowLogs} />
            </Col>
          </Row>
          <div className={'create-mission-modal'}>
            <Modal show={showCreateMission} onHide={() => setShowCreateMission(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  <p className={'card-subtitle'}>Vehicle - {props.vehicle.display_identifier}</p>
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={onSubmitMissionCreation}>
                <Modal.Body className={'justify-content-between'}>
                  <Form.Group as={Row} className={'d-flex align-items-center'}>
                    <Col sm={3}>
                      <Form.Label>{t('vehicle.create-mission-destination')}</Form.Label>
                    </Col>
                    <Col sm={9}>
                      <Form.Select id={'nodesSelect'} className={'rounded-5'}>
                        {geography?.data?.map((node: NodeType, i: Key) => (
                          <option value={Number(node?.padam_id)} key={i}>
                            {node?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  {territoryConfig?.data?.mission.activity_enabled &&
                  territoryConfig?.data?.mission.waypoints_enabled ? (
                    <>
                      <Form.Group as={Row} className={'d-flex align-items-center mt-3'}>
                        <Col sm={3}>
                          <Form.Label>{t('vehicle.activity')}</Form.Label>
                        </Col>
                        <Col sm={9}>
                          <Form.Select id={'activitiesSelect'} className={'rounded-5'}>
                            {PLACE_ACTIVITIES.map(activity => (
                              <option value={activity.number} key={activity.number}>
                                {activity.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <></>
                  )}
                </Modal.Body>
                <Modal.Footer className={'border-0'}>
                  <Button
                    type={'submit'}
                    className={'submit-button m-auto mb-3 mt-2'}
                    disabled={isCreatingMission}>
                    {isCreatingMission ? (
                      <span className={'d-flex justify-content-center'}>
                        <Spinner size={'sm'} animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <span className={'ms-2'}>{t('vehicle.create-mission-submit')}</span>
                      </span>
                    ) : (
                      <p>
                        <img src={'/theme/media/plus.svg'} width="25" height="25" alt="Clock icon" />
                        <span className={'ms-1'}>{t('vehicle.create-mission-submit')}</span>
                      </p>
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
