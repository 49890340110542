import { useQuery } from '@tanstack/react-query';
import { getVehiclesAutomataLogs, getVehiclesLogs } from '../queries/getVehicles.ts';
import { TerritoryConfig } from '../interfaces/TerritoryConfig.ts';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function addIndex(logs) {
  for (let i = 0; i < logs.length; i++) {
    logs[i].id = i;
  }
  return logs;
}

export const useAutomataLogs = (territorySlug: string, vehicleId: number) => {
  return useQuery({
    queryKey: ['automataLogs', territorySlug, vehicleId],
    queryFn: () => getVehiclesAutomataLogs(territorySlug, vehicleId),
    refetchInterval: 10000,
    select: data => addIndex(data),
  });
};

export const useVehicleSystemLogs = (
  territorySlug: string,
  vehicleId: number,
  territoryConfig: TerritoryConfig | null | undefined,
) => {
  return useQuery({
    queryKey: ['vehicleLogs', territorySlug, vehicleId],
    queryFn: () => getVehiclesLogs(territorySlug, vehicleId),
    enabled: territoryConfig?.vehicle?.events_display === true,
    refetchInterval: 10000,
    select: data => addIndex(data),
  });
};
