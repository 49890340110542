import { Dashboard as DashboardComponent } from '../components/Dashboard';
import Layout from '../components/Layout';
import { Suspense } from 'react';

export function Dashboard() {
  return (
    <Layout>
      <Suspense>
        <DashboardComponent />
      </Suspense>
    </Layout>
  );
}
