import { useQuery } from '@tanstack/react-query';
import { getRoadmap } from '../queries/getRoadmap.ts';

export const useRoadmap = (territorySlug: string, vehicleId: number) => {
  return useQuery({
    queryKey: ['roadmap', territorySlug, vehicleId],
    queryFn: () => getRoadmap(territorySlug, vehicleId),
    enabled: vehicleId !== undefined,
    retry: false,
  });
};
