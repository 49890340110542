import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { Vehicle } from './Vehicle.tsx';
import { useEffect, useState } from 'react';
import { useVehiclesShiftByTerritory } from '../../../services/Vehicle.ts';
import { useParams } from 'react-router-dom';

export function Shift() {
  const { t } = useTranslation();
  const [vehiclesConnected, setVehiclesConnected] = useState<undefined | number>();
  const [orderedVehicles, setOrderedVehicles] = useState<undefined | IVehicle[]>();
  const params = useParams() as { territorySlug: string };
  const vehicles = useVehiclesShiftByTerritory(params.territorySlug);

  useEffect(() => {
    setVehiclesConnected(vehicles?.data?.filter((x: IVehicle) => x?.is_online).length);
    setOrderedVehicles(vehicles?.data?.sort((a: IVehicle, b: IVehicle) => a.id - b.id));
  }, [vehicles?.data]);

  return (
    <div className={'driver-shift-container'}>
      <div className={'row m-0 p-0 pt-3 pb-3'}>
        <div>
          <p className={'card-title ms-2'}>
            <img src={'/theme/media/shift-icon.svg'} width="56" height="42" alt="Journey icon" />
            <span className={'ms-3'}>{t('shift.title')}</span>
          </p>
        </div>
      </div>
      <div className={'ms-3 mt-2'}>
        <p className={'card-subtitle text-center'}>
          {vehiclesConnected !== undefined && vehiclesConnected !== 0 ? (
            <span>
              {vehiclesConnected} / {vehicles?.data?.length} {t('shift.vehicle-connected')}
            </span>
          ) : (
            <span>{t('shift.vehicle-disconnected')}</span>
          )}
        </p>
      </div>
      <Accordion className={'mb-5 ms-4 me-4'}>
        <div className={'accordion accordion-flush mt-4 text-center'}>
          {orderedVehicles?.map((vehicle: IVehicle, i: number) => (
            <Vehicle vehicle={vehicle} key={i} index={i} />
          ))}
        </div>
      </Accordion>
    </div>
  );
}
