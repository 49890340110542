import { useLiveStatistics } from '../../../services/Statistics.ts';
import { Spinner } from 'react-bootstrap';
import {
  KPI_LIST,
  KPI_TERRITORY,
  KPI_TERRITORY_AVERAGE_AUTONOMOUS_MODE_RATIO,
  KPI_TERRITORY_AVERAGE_PARTNER_MODE_RATIO,
  KPI_TERRITORY_AVERAGE_SPEED,
} from '../../../constants.ts';
import { t } from 'i18next';
import { OverlayTooltip } from '../../../ui/Tooltip.tsx';
import { useEffect, useState } from 'react';
import { DailyKpi } from '../../../interfaces/Kpi.ts';
import { useParams } from 'react-router-dom';

export function Kpi() {
  const params = useParams() as { territorySlug: string };
  const { data: statisticsData, isLoading } = useLiveStatistics(params.territorySlug);
  const [todayData, setTodayData] = useState();
  const [yesterdayData, setYesterdayData] = useState();
  const [deltaData, setDeltaData] = useState<Record<string, number> | undefined>();
  function computeDeltaData(todayData: DailyKpi | undefined, yesterdayData: DailyKpi | undefined) {
    if (todayData !== undefined && yesterdayData !== undefined) {
      const response: Record<string, number> = {};
      for (let index = 0; index < KPI_LIST.length; index++) {
        const todayValue = todayData[KPI_LIST[index]];
        const yesterdayValue = yesterdayData[KPI_LIST[index]];
        if (
          typeof todayValue === 'number' &&
          typeof yesterdayValue === 'number' &&
          yesterdayValue !== 0
        ) {
          response[KPI_LIST[index]] = Math.round(((todayValue - yesterdayValue) / yesterdayValue) * 100);
        }
      }
      return response;
    }
  }
  function getTextColor(number: number): string {
    return number > 0 ? 'text-success' : 'text-danger';
  }

  useEffect(() => {
    if (statisticsData?.data !== undefined) {
      const today = new Date().toISOString().split('T')[0];
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayStr = yesterday.toISOString().split('T')[0];
      const todayKpi = statisticsData?.data?.[KPI_TERRITORY].find((kpi: DailyKpi) =>
        kpi.day.startsWith(today),
      );
      const yesterdayKpi = statisticsData?.data?.[KPI_TERRITORY].find((kpi: DailyKpi) =>
        kpi.day.startsWith(yesterdayStr),
      );
      setTodayData(todayKpi || undefined);
      setYesterdayData(yesterdayKpi || undefined);
      setDeltaData(computeDeltaData(todayKpi, yesterdayKpi));
    }
  }, [statisticsData]);

  return (
    <div className={'top-center-container d-flex align-items-center'}>
      <div className={'row w-100 m-0'}>
        {isLoading ? (
          <Spinner animation="border" role="status" className={'mx-auto'}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <div className={'col-4 my-auto text-center'}>
              <p className={'card-title'}>
                {todayData ? Math.round(todayData[KPI_TERRITORY_AVERAGE_PARTNER_MODE_RATIO] * 100) : '-'}{' '}
                %
              </p>
              <p className={'text-basic'}>
                {t('kpi.partner-mode')}
                <OverlayTooltip text={t('kpi.partner-mode-description')}>
                  <img
                    src={'/theme/media/info.svg'}
                    width="15"
                    height="15"
                    className={'ms-1 ariane'}
                    alt="Info icon"
                  />
                </OverlayTooltip>
              </p>
              {deltaData ? (
                <p className={'text-basic'}>
                  <span className={getTextColor(deltaData[KPI_TERRITORY_AVERAGE_PARTNER_MODE_RATIO])}>
                    {deltaData[KPI_TERRITORY_AVERAGE_PARTNER_MODE_RATIO]} %
                  </span>{' '}
                  vs previous day :{' '}
                  {yesterdayData
                    ? Math.round(yesterdayData[KPI_TERRITORY_AVERAGE_PARTNER_MODE_RATIO] * 100)
                    : '-'}{' '}
                  %
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className={'col-4 my-auto text-center'}>
              <p className={'card-title'}>
                {todayData ? Math.round(todayData[KPI_TERRITORY_AVERAGE_SPEED]) : '-'} km/h
              </p>
              <p className={'text-basic'}>
                {t('kpi.average-speed')}
                <OverlayTooltip text={t('kpi.average-speed-description')}>
                  <img
                    src={'/theme/media/info.svg'}
                    width="15"
                    height="15"
                    className={'ms-1 ariane'}
                    alt="Info icon"
                  />
                </OverlayTooltip>
              </p>
              {deltaData ? (
                <p className={'text-basic'}>
                  <span className={getTextColor(deltaData[KPI_TERRITORY_AVERAGE_SPEED])}>
                    {deltaData[KPI_TERRITORY_AVERAGE_SPEED]} %
                  </span>{' '}
                  vs previous day :{' '}
                  {yesterdayData ? Math.round(yesterdayData[KPI_TERRITORY_AVERAGE_SPEED]) : '-'} km/h
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className={'col-4 my-auto text-center'}>
              <p className={'card-title'}>
                {todayData
                  ? Math.round(todayData[KPI_TERRITORY_AVERAGE_AUTONOMOUS_MODE_RATIO] * 100)
                  : '-'}{' '}
                %
              </p>
              <p className={'text-basic'}>
                {t('kpi.autonomous-mode')}
                <OverlayTooltip text={t('kpi.autonomous-mode-description')}>
                  <img
                    src={'/theme/media/info.svg'}
                    width="15"
                    height="15"
                    className={'ms-1 ariane'}
                    alt="Info icon"
                  />
                </OverlayTooltip>
              </p>
              {deltaData ? (
                <p className={'text-basic'}>
                  <span className={getTextColor(deltaData[KPI_TERRITORY_AVERAGE_AUTONOMOUS_MODE_RATIO])}>
                    {deltaData[KPI_TERRITORY_AVERAGE_AUTONOMOUS_MODE_RATIO]} %
                  </span>{' '}
                  vs previous day :{' '}
                  {yesterdayData
                    ? Math.round(yesterdayData[KPI_TERRITORY_AVERAGE_AUTONOMOUS_MODE_RATIO] * 100)
                    : '-'}{' '}
                  %
                </p>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
