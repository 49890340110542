import { Accordion } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { MissionCard } from './MissionCard.tsx';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { websocketUrl } from '../../../queries/constants.ts';
import { IMission } from '../../../interfaces/IMission.ts';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { processMissionMessage } from '../../../websocket/processing.ts';
import { MissionMessage } from '../../../interfaces/Message.ts';
import { useMissions } from '../../../services/Mission.ts';
import { LoadingButton } from '../../../ui/Button.tsx';
import { useTerritoryConfig } from '../../../services/Territory.ts';
import { useWebsocketToken } from '../../../services/Websocket.ts';

export function Mission() {
  const { selectedVehicle } = useContext(SelectedVehicleContext);
  const navigate = useNavigate();
  const params = useParams() as { territorySlug: string };
  const { t } = useTranslation();
  const [missionList, setMissionList] = useState([]);
  const [queryParams, setQueryParams] = useState<Record<string, string>>({ page: String(1) });

  const { data: missionData } = useMissions(params.territorySlug, queryParams);
  const { data: territoryConfig } = useTerritoryConfig(params.territorySlug);
  const { data: websocketToken } = useWebsocketToken();

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket<MissionMessage>(websocketUrl, {
    share: false,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (missionData?.data !== undefined) {
      if (selectedVehicle?.id !== undefined) {
        setQueryParams({
          vehicle_display_identifier: selectedVehicle.display_identifier,
          page: String(1),
        });
      } else {
        setQueryParams({ page: String(1) });
      }
    }
    setMissionList(missionData?.data['results']);
  }, [missionData?.data, selectedVehicle?.id]);

  useEffect(() => {
    if (
      territoryConfig?.data?.mission.websocket_enabled &&
      readyState === ReadyState.OPEN &&
      websocketToken?.data &&
      missionList !== undefined &&
      missionList.length > 0
    ) {
      sendJsonMessage({
        type: 'init',
        auth_token: websocketToken.data.WEBSOCKET_TOKEN,
        object_type: 'mission',
        object_id_list: missionList.map((mission: IMission) => mission.provider_identifier),
      });
    }
  }, [readyState, websocketToken?.data, missionList, territoryConfig?.data]);

  useEffect(() => {
    if (lastJsonMessage?.message_type === 'mission' && missionList !== undefined) {
      processMissionMessage(lastJsonMessage, missionList);
      setMissionList(missionList);
    } else {
      setMissionList(missionData?.data['results']);
    }
  }, [lastJsonMessage]);

  const redirectMissionHistory = () => {
    navigate(`/mission-history/${params.territorySlug}/`);
  };

  return (
    <div className={'mission-container'}>
      <div className={'row m-0 p-0 pt-3 pb-3'}>
        <div>
          <p className={'card-title ms-2'}>
            <img src={'/theme/media/mission-icon.svg'} width="56" height="42" alt="Mission icon" />
            <span className={'ms-3'}>{t('mission.title')}</span>
          </p>
        </div>
      </div>
      <Accordion className={'mission-scroll-container'}>
        <div className={'accordion accordion-flush text-center'}>
          {missionList?.map((mission: IMission, i: number) => (
            <MissionCard key={i} mission={mission} index={i} />
          ))}
        </div>
      </Accordion>
      <div className={'text-center mt-3'}>
        <LoadingButton
          type={'green-button'}
          text={t('mission.history-link')}
          imagePath={'/theme/media/green-clock.svg'}
          loading={false}
          onClick={() => redirectMissionHistory()}
        />
      </div>
    </div>
  );
}
