import axios from 'axios';
import { getAlertsUrl, header, patchAlertsUrl } from './constants.ts';

export async function getAlert(territorySlug: string) {
  return await axios.get(getAlertsUrl(territorySlug), { headers: header });
}

export async function patchAlert(territorySlug: string, alertId: number, data: { lifecycle: string }) {
  return await axios.patch(patchAlertsUrl(territorySlug, alertId), data, { headers: header });
}
