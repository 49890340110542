import { useQuery } from '@tanstack/react-query';
import { getMissionHistory, getMissions, getMissionsDetail } from '../queries/getMissions.ts';

export const useMissions = (territorySlug: string, queryParams: Record<string, string>) => {
  return useQuery({
    queryKey: ['missions', territorySlug, queryParams],
    queryFn: () => getMissions(territorySlug, queryParams),
    refetchInterval: 10000,
  });
};

export const useMission = (territorySlug: string, missionId: string) => {
  return useQuery({
    queryKey: ['mission', territorySlug, missionId],
    queryFn: () => getMissionsDetail(territorySlug, missionId),
  });
};

export const useMissionHistory = (territorySlug: string, missionId: string) => {
  return useQuery({
    queryKey: ['mission-history', territorySlug, missionId],
    queryFn: () => getMissionHistory(territorySlug, missionId),
  });
};
