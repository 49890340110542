import { useState } from 'react';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchVehicle } from '../../../queries/getVehicles.ts';
import { Form, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { LoadingButton } from '../../../ui/Button.tsx';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface MutationPayload {
  territorySlug: string;
  vehicleId: number;
  content: { display_identifier: string };
}

interface Props {
  vehicle: IVehicle;
  showDisplayIdentifierModal: boolean;
  setShowDisplayIdentifierModal: (state: boolean) => void;
}

export function VehicleDisplayIdentifier(props: Props) {
  const params = useParams() as { territorySlug: string };
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: MutationPayload) =>
      patchVehicle(payload.territorySlug, payload.vehicleId, payload.content),
    onSuccess: () => {
      toast.success(`${t('vehicle-display-identifier.success')}`);
      setIsButtonLoading(false);
      props.setShowDisplayIdentifierModal(false);
      queryClient.invalidateQueries({ queryKey: ['vehicles', params.territorySlug] });
    },
    onError: () => {
      toast.error(`${t('vehicle-display-identifier.fail')}`);
    },
    onSettled: () => {
      setIsButtonLoading(false);
    },
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function onSubmit(event) {
    event.preventDefault();
    setIsButtonLoading(true);
    mutation.mutate({
      territorySlug: params.territorySlug,
      vehicleId: props.vehicle.id,
      content: { display_identifier: event.target.elements.displayIdentifier.value },
    });
  }

  return (
    <Modal
      show={props.showDisplayIdentifierModal}
      onHide={() => props.setShowDisplayIdentifierModal(false)}
      centered>
      <Modal.Header closeButton className={'border-0 mb-0 pb-0'}>
        <Modal.Title>
          <p className={'card-title'}>{t('vehicle-display-identifier.title')}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'mt-0 pt-0'}>
        <div>
          <p className={'card-subtitle'}>
            {t('vehicle.vehicle')} {props.vehicle.display_identifier}
          </p>
        </div>
        <Form className={'m-0 p-0 mt-4'} onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>
              <p className={'card-text'}>{t('vehicle-display-identifier.commercial-name')}</p>
            </Form.Label>
            <Form.Control
              id={'displayIdentifier'}
              type="text"
              placeholder={props.vehicle.display_identifier}
              className={'rounded-5'}
            />
          </Form.Group>
          <div className={'text-center mt-3'}>
            <LoadingButton
              type={'submit-button'}
              text={t('vehicle-display-identifier.submit-button')}
              imagePath={null}
              loading={isButtonLoading}
              onClick={undefined}
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
